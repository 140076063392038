import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  display: -ms-flexbox;
  flex: auto;
  -ms-flex: auto;
  -ms-flex-pack: center;
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 10px;
  position: relative;
  h1 {
    color: #ec626b;
    padding-top: 1rem;
  }
`

const Form = styled.form`
  position: relative;
  width: 100%;
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  div.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    flex-wrap: nowrap;
  }
  label:not(.form-check-label) {
    width: 30%;
    margin-bottom: 0;
    min-width: 70px;
    padding: 11px 0;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #000;
    background-color: transparent;
    -webkit-animation: 1ms void-animation-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }
`

const Fieldset = styled.fieldset`
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #fff5c9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`
const Button = styled.button`
  background-color: #ec626b;
  border-radius: 4px;
  border-style: none;
  box-shadow: 0 6px 9px rgba(93, 92, 50, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 600;
  height: 40px;
  margin: 40px 15px 0;
  outline: none;
  overflow: hidden;
  position: relative;
  width: calc(100% - 30px);
`
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContattiPage extends React.Component {
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null, status: undefined }
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    console.log(
      encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      })
    )
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() =>
        this.setState({
          status: "success",
          feedbackMsg:
            "Grazie per averci scritto! Ti risponderemo appena possibile!",
        })
      )
      .catch(error =>
        this.setState({
          status: "error",
          feedbackMsg: "Qualcosa è andato storto... potresti riprovare?",
        })
      )
  }

  render() {
    return (
      <Layout>
        <SEO title="Contatti" />
        <Container>
          <h1>Scrivici!</h1>
          <Form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </p>
            <Fieldset>
              <div className="row">
                <label htmlFor="name">Nome:</label>
                <input
                  aria-label="Nome"
                  type="text"
                  name="name"
                  placeholder="Il tuo nome"
                  required
                  onChange={this.handleChange}
                />
              </div>
            </Fieldset>
            <Fieldset>
              <div className="row">
                <label htmlFor="email">Email:</label>
                <input
                  aria-label="Email"
                  type="email"
                  name="email"
                  placeholder="La tua email"
                  required
                  onChange={this.handleChange}
                />
              </div>
            </Fieldset>
            <Fieldset>
              <div className="row">
                <label htmlFor="message">Messaggio:</label>
                <textarea
                  aria-label="Messaggio"
                  name="message"
                  col="20"
                  placeholder="Il tuo messaggio"
                  required
                  onChange={this.handleChange}
                />
              </div>
            </Fieldset>
            {this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}

            <Button type="submit">Invia</Button>
          </Form>
        </Container>
      </Layout>
    )
  }
}

export default ContattiPage
